'use client'

import { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
// Remove direct imports of motion and AnimatePresence
// import { motion, AnimatePresence } from 'framer-motion'
import { ArrowUpIcon } from '@heroicons/react/24/outline'

// Dynamically import the animated part
const AnimatedButton = dynamic(() => import('framer-motion').then(mod => {
  // Need to return a component that uses motion and AnimatePresence
  const { motion, AnimatePresence } = mod;
  return ({ isVisible, scrollToTop }: { isVisible: boolean, scrollToTop: () => void }) => (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 z-50 p-3 rounded-full bg-yellow-500 text-white shadow-lg hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'spring', stiffness: 300, damping: 20 }}
          aria-label="Back to top"
        >
          <ArrowUpIcon className="h-5 w-5" />
        </motion.button>
      )}
    </AnimatePresence>
  );
}), { ssr: false }); // Disable SSR for this client-side animation component

export function BackToTop() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Show button when page is scrolled down
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  // Render the dynamically imported component when isVisible is true
  return <AnimatedButton isVisible={isVisible} scrollToTop={scrollToTop} />;
}
