'use client'

import { ReactNode } from 'react' // Removed useEffect, useState
import { NextIntlClientProvider, AbstractIntlMessages } from 'next-intl' // Added AbstractIntlMessages
import { Header } from './Header'
import { Footer } from './Footer'
import { TopBar } from './TopBar'
import { BackToTop } from '../ui/BackToTop'
// import { defaultLocale } from '@/config/i18n' // No longer needed here

// Re-add messages and locale to Props
type Props = {
  messages: AbstractIntlMessages
  locale: string
  children: ReactNode
}

// Accept messages and locale props again
export function ClientLayout({ children, messages, locale }: Props) {
  // No state or effect needed here

  return (
    // Wrap the entire content with NextIntlClientProvider
    <NextIntlClientProvider
      messages={messages}
      locale={locale}
      timeZone="Europe/Paris" // Or your desired timezone
      // onError can be added here if needed for client-side errors
      // onError={(error) => console.error('NextIntlClientProvider Error:', error)}
    >
      <div className="min-h-screen flex flex-col bg-gray-50">
        <div className="fixed inset-x-0 top-0 z-50">
          <TopBar className="sm:block hidden" />
          <Header />
         </div>
         {/* Restore flex to main, along with flex-grow, add centering and max-width */}
         <main className="flex flex-grow mt-[60px] sm:mt-[104px] mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8"> {/* Added mx-auto, w-full, max-w-7xl and padding */}
           {children}
         </main>
        <Footer />
        <BackToTop />
      </div>
    </NextIntlClientProvider> // Add closing tag
  )
}
