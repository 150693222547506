'use client'

import { LocaleLink } from '../ui/LocaleLink'
import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { LanguageSwitcher } from './LanguageSwitcher'
// import { ServiceDropdown } from './ServiceDropdown' // Removed
// import { MobileServiceDropdown } from './MobileServiceDropdown' // Removed
import { useState, useEffect } from 'react'
// Removed framer-motion imports
import { XMarkIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline'
import { CONTACT_INFO } from '@/config/constants'

export function Header() {
  // Updated to use the modular structure
  const tNav = useTranslations('navigation')
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  
  // Handle scroll event for sticky header
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 10)
    }
    
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <header className={`bg-white w-full z-50 transition-all duration-300 ${scrolled ? 'shadow-md sticky top-0' : ''}`}>
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <LocaleLink href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">E-Taxi</span>
            <div className="h-8 w-32" style={{ position: 'relative' }}>
              <Image
                src="/images/logo-header.png" // Updated logo path
                alt="E-Taxi Logo" // Updated alt text
                aria-hidden="true"
                fill
                sizes="(max-width: 768px) 100vw, 128px"
                style={{ objectFit: 'contain' }}
                priority
              />
            </div>
          </LocaleLink>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:bg-gray-100"
          >
            <span className="sr-only">Open main menu</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <LocaleLink href="/" className="text-sm font-semibold leading-6 text-gray-900">
            {tNav('home')}
          </LocaleLink>
          <LocaleLink href="/booking" className="text-sm font-semibold leading-6 text-gray-900">
            {tNav('bookNow')}
          </LocaleLink>
          {/* START: Replace ServiceDropdown */}
          <LocaleLink href="/services/particulier" className="text-sm font-semibold leading-6 text-gray-900">
            {tNav('servicesParticulier')}
          </LocaleLink>
          <LocaleLink href="/services/entreprise" className="text-sm font-semibold leading-6 text-yellow-600 hover:text-yellow-700">
            {tNav('servicesEntreprise')} {/* Added emphasis */}
          </LocaleLink>
          {/* END: Replace ServiceDropdown */}
          <LocaleLink href="/pricing" className="text-sm font-semibold leading-6 text-gray-900">
            {tNav('pricing')}
          </LocaleLink>
          <LocaleLink href="/blog" className="text-sm font-semibold leading-6 text-gray-900">
            {tNav('blog')}
          </LocaleLink>
          <LocaleLink href="/contact" className="text-sm font-semibold leading-6 text-gray-900">
            {tNav('contact')}
          </LocaleLink>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:gap-x-6">
          <LanguageSwitcher />
          {/* Login link removed as page doesn't exist */}
        </div>
      </nav>

      {/* Mobile menu - Replaced framer-motion with CSS transitions */}
      <div
        className={`fixed inset-0 z-50 lg:hidden transition-opacity duration-200 ease-linear ${
          mobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      >
        {/* Background overlay */}
        <div
          className="fixed inset-0 bg-gray-900/70"
          onClick={() => setMobileMenuOpen(false)}
          aria-hidden="true" // Hide from accessibility when menu is closed implicitly by opacity
        />

        {/* Mobile menu panel */}
        <div
          className={`fixed inset-y-0 right-0 w-full max-w-sm bg-white px-6 py-6 overflow-y-auto transform transition-transform duration-300 ease-in-out ${
            mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="flex items-center justify-between">
            <LocaleLink href="/" className="-m-1.5 p-1.5" onClick={() => setMobileMenuOpen(false)}>
                  <span className="sr-only">E-Taxi</span>
                  <div className="h-8 w-32" style={{ position: 'relative' }}>
                    <Image
                      src="/images/logo-header.png" // Updated logo path
                      alt="E-Taxi Logo" // Updated alt text
                      aria-hidden="true"
                      fill
                      sizes="(max-width: 768px) 100vw, 128px"
                      style={{ objectFit: 'contain' }}
                      priority
                    />
                  </div>
                </LocaleLink>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700 hover:bg-gray-100"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="space-y-2 py-6">
                  <LocaleLink
                    href="/"
                    className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {tNav('home')}
                  </LocaleLink>
                  <LocaleLink
                    href="/booking"
                    className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {tNav('bookNow')}
                  </LocaleLink>
                  {/* START: Replace MobileServiceDropdown */}
                  <LocaleLink
                    href="/services/particulier"
                    className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {tNav('servicesParticulier')}
                  </LocaleLink>
                  <LocaleLink
                    href="/services/entreprise"
                    className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-yellow-600 hover:bg-yellow-50" // Added emphasis
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {tNav('servicesEntreprise')}
                  </LocaleLink>
                  {/* END: Replace MobileServiceDropdown */}
                  <LocaleLink
                    href="/pricing"
                    className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {tNav('pricing')}
                  </LocaleLink>
                  <LocaleLink
                    href="/blog"
                    className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {tNav('blog')}
                  </LocaleLink>
                  <LocaleLink
                    href="/contact"
                    className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {tNav('contact')}
                  </LocaleLink>
                </div>
                <div className="border-t border-gray-200 py-6">
                  {/* Contact Information */}
                  <div className="mb-4 space-y-3">
                    <a 
                      href={`tel:${CONTACT_INFO.phone}`} 
                      className="flex items-center px-3 py-2 text-base text-gray-700 hover:text-yellow-800 transition-colors"
                    >
                      <PhoneIcon className="h-5 w-5 mr-3 text-gray-400" />
                      {CONTACT_INFO.phone}
                    </a>
                    <a 
                      href={`mailto:${CONTACT_INFO.email}`} 
                      className="flex items-center px-3 py-2 text-base text-gray-700 hover:text-yellow-800 transition-colors"
                    >
                      <EnvelopeIcon className="h-5 w-5 mr-3 text-gray-400" />
                      {CONTACT_INFO.email}
                    </a>
                  </div>
                  {/* Wrap LanguageSwitcher in a centering div to prevent overflow */}
                  <div className="flex justify-center">
                    <LanguageSwitcher />
                  </div>
                  {/* Login link removed as page doesn't exist */}
                </div>
              </div>
            </div> {/* End Mobile menu panel */}
          </div> {/* End Mobile menu container */}
    </header>
  ); // Ensure semicolon is removed if present
}
