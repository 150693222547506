'use client'

import { CONTACT_INFO } from '@/config/constants'
import { PhoneIcon } from '@heroicons/react/24/outline'
import { LocaleLink } from '@/components/ui/LocaleLink' // Keep LocaleLink
import { useTranslations } from 'next-intl'
import { Button } from '@/components/ui/Button' // Import Button

interface TopBarProps {
  className?: string
}

export function TopBar({ className = '' }: TopBarProps) {
  const t = useTranslations('common')
  return (
    <div className={`bg-[#8B4513] text-white py-2 px-4 ${className}`}>
      {/* Stack vertically on small screens, horizontal on medium and up */}
      <div className="max-w-7xl mx-auto flex flex-col gap-y-2 sm:flex-row sm:justify-between sm:items-center">
        {/* Adjust spacing and alignment for stacking */}
        <div className="flex flex-wrap items-center justify-center sm:justify-start gap-x-4 gap-y-1">
          <a href={`tel:${t('contact.phone')}`} className="flex items-center space-x-1 hover:text-yellow-200 text-sm p-1"> {/* Added padding */}
            <PhoneIcon className="h-4 w-4 flex-shrink-0" /> {/* Added flex-shrink-0 */}
            <span className="whitespace-nowrap">{t('contact.phone')}</span> {/* Prevent wrapping */}
          </a>
          <a href={`tel:${t('contact.phone_b2b')}`} className="flex items-center space-x-1 hover:text-yellow-200 text-sm p-1"> {/* Added padding */}
            <PhoneIcon className="h-4 w-4 flex-shrink-0" /> {/* Added flex-shrink-0 */}
            <span className="whitespace-nowrap">{t('contact.phone_b2b')}</span> {/* Prevent wrapping */}
          </a>
          <a href={`mailto:${t('contact.email')}`} className="flex items-center space-x-1 hover:text-yellow-200 text-sm p-1"> {/* Added padding */}
            <span className="whitespace-nowrap">{t('contact.email')}</span> {/* Prevent wrapping */}
          </a>
        </div>
         {/* Adjust spacing and alignment for stacking */}
        <div className="flex items-center justify-center sm:justify-end space-x-4">
          {/* Replace the styled <a> tag with Button component wrapped in LocaleLink */}
          <LocaleLink href="/live" passHref>
            <Button variant="primary" size="sm" className="rounded-full text-black space-x-1 text-sm py-1"> {/* Match styling */}
              <span className="inline-block w-2 h-2 bg-green-500 rounded-full animate-pulse flex-shrink-0"></span>
              <span className="whitespace-nowrap">{t('navigation.liveTitle')}</span>
              <span className="bg-black/10 px-1.5 rounded">{t('navigation.liveTaxiCount', { count: 12 })}</span>
            </Button>
          </LocaleLink>
          <div className="flex items-center space-x-1">
            <span>{t('country.flag')}</span>
            <span>{t('country.name')}</span>
            {/* Removed duplicate country name span */}
          </div>
        </div>
      </div>
    </div>
  )
}

// Remove the unused EnhancedLiveTaxiButton function
