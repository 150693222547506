/**
 * Application-wide constants
 * This file contains constants that are used throughout the application
 * and should not change frequently.
 */

export const CONTACT_INFO = {
  email: 'contact@etaxi.tn',
  phone: '+216 31 324 324',
  b2bResponsible: '+216 29229922',
  address: '2, Avenue Hédi Chaker, Carthage Salambo',
  businessHours: {
    weekdays: '8am - 8pm',
    saturday: '9am - 5pm',
    sunday: 'Closed'
  }
}

export const SOCIAL_MEDIA = {
  facebook: 'https://www.facebook.com/etaxi.tn',
  instagram: 'https://www.instagram.com/etaxi.tn',
  twitter: 'https://twitter.com/etaxi_tn',
  linkedin: 'https://www.linkedin.com/company/etaxi-tn'
}

export const COMPANY_INFO = {
  name: 'E-Taxi Tunisia',
  foundedYear: 2014,
  slogan: 'Tunisia\'s Premier B2B Transportation Solution'
}
