'use client'

import { LocaleLink } from '../ui/LocaleLink'
import { useTranslations } from 'next-intl'

const navigation = {
  main: [
    { name: 'about', href: '/about' },
    { name: 'servicesParticulier', href: '/services/particulier' }, // Updated
    { name: 'servicesEntreprise', href: '/services/entreprise' }, // Added
    { name: 'blog', href: '/blog' },
    { name: 'contact', href: '/contact' },
  ],
  legal: [
    { name: 'privacy', href: '/privacy' },
    { name: 'terms', href: '/terms' },
  ],
}

export function Footer() {
  const tNav = useTranslations('navigation')
  const tSocial = useTranslations('common.social')

  const social = [
    {
      name: tSocial('facebook.name'),
      href: tSocial('facebook.url'),
      icon: (props: React.SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: tSocial('twitter.name'),
      href: tSocial('twitter.url'),
      icon: (props: React.SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ]

  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <LocaleLink href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                {tNav(item.name)}
              </LocaleLink>
            </div>
          ))}
        </nav>
        <nav className="mt-8 -mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Legal">
          {navigation.legal.map((item) => (
            <div key={item.name} className="pb-6">
              <LocaleLink href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                {tNav(item.name)}
              </LocaleLink>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-10">
          {social.map((item) => (
            <LocaleLink key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </LocaleLink>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; {new Date().getFullYear()} {tNav('copyright')}
        </p>
      </div>
    </footer>
  )
}
